<template>
  <v-row no-gutters>
    <v-col class="navBar border-right" v-if="!sidebarCollapse">
      <v-sheet :height="height" class="overflow-auto">
        <v-row no-gutters class="mb-2">
          <v-col cols="12">
            <v-row class="px-3 pt-2" no-gutters align="center">
              <v-col><h3 class="font-weight-regular">{{$tools.dateText(daySelected)}}</h3></v-col>
              <v-col cols="auto">
                <v-btn icon @click="setToday">
                  <v-icon v-if="isToday" color="primary" small>mdi-checkbox-blank-circle</v-icon>
                  <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-date-picker
                v-model="daySelected"
                :show-current="true"
                :first-day-of-week="1"
                no-title
                show-adjacent-months
                color="primary"
                full-width
            /> -->
            <date-picker :daySelected.sync="daySelected" cookieName="userSpecPlansDay"/>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="6" class="px-3">
            <v-btn block depressed color="silver" @click="zoomChange(-1)">
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="pr-3">
            <v-btn block depressed color="silver" @click="zoomChange(1)">
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="px-3 pt-3">
            <v-btn block depressed color="silver" to="/userSpecPlan/">
              День
            </v-btn>
          </v-col>
          <v-col cols="6" class="pr-3 pt-3">
            <v-btn block depressed color="silver" @click="copyState.modalOpen(1)">
              Копировать
            </v-btn>
            <dialog-view title="Копирование расписания" :open="copyState.isModalOpen" @cancel="copyState.modalClose()">
              <template v-slot:content>
                <div v-if="copyState.modalData===1">
                  <div class="caption">
                    Выберите промежуток который вы хотите скопировать
                  </div>
                  <v-date-picker
                      v-model="copyState.modalData1"
                      range
                      :show-current="true"
                      :first-day-of-week="1"
                      no-title
                      show-adjacent-months
                      color="primary"
                      full-width
                  />
                </div>
                <div v-if="copyState.modalData===2">
                  <div class="caption">
                    Выберите день с которого необходимо вставить выбранный промежуток
                  </div>
                  <v-date-picker
                      v-model="copyState.modalData2"
                      :show-current="true"
                      :first-day-of-week="1"
                      no-title
                      show-adjacent-months
                      color="primary"
                      full-width
                  />
                </div>
                <div v-if="copyState.modalData===3">
                  <div class="caption mb-3">
                    Выберите филиал
                  </div>
                  <com-branch-autocomplete-multiple :select.sync="copyState.modalData3"/>
                </div>
              </template>
              <template v-slot:actions>
                <v-btn v-if="copyState.modalData===1" @click="copyState.modalData=2" color="primary" text>Далее</v-btn>
                <v-btn v-if="copyState.modalData===2" @click="copyState.modalData=3" color="primary" text>Далее</v-btn>
                <v-btn v-if="copyState.modalData===3&&copyState.modalData3" @click="copy()" color="primary" text>Копировать</v-btn>
              </template>
            </dialog-view>
            <progress-page :loading="copyState.isLoading" icon="mdi-content-copy" dialog/>
          </v-col>
        </v-row>
        <div class="px-3">
          <com-branch-autocomplete-multiple :select.sync="comBranchsSelected" globalState/>
          <spec-select :specsSelected.sync="specsSelected"/>
          <user-autocomplete-multiple name="Специалисты" _type="spec" :select.sync="usersSelected"/>
          <checkboxes :items="[{id:'deleted', name:'Показать удалённые'}]" :selects.sync="showDeleted"/>
        </div>
      </v-sheet>
    </v-col>
    <v-col>
      <v-sheet class="calendarSheet" v-if="comRooms.length>0" :style="{cursor:cursorStyle}">
        <v-btn 
          @click="sidebarCollapse = !sidebarCollapse;"
          class="ma-2 sidebar-collapse-btn"
          fab
          depressed
        >
          <v-icon v-if="!sidebarCollapse">mdi-arrow-expand-left</v-icon>
          <v-icon v-if="sidebarCollapse">mdi-arrow-expand-right</v-icon>
        </v-btn>

        <div class="work-schedule" :style="{height:height+'px',right:userSpecPlanState.isModalOpen ? '440px' : '0'}" @mouseup.right.stop="loadState.isSuccess&&eventDrag!==null  ? cancelDrag(eventDrag) : null"  @scroll="calendarScroll($event)" ref="scrollContainer">
          
          <div class="work-schedule__dates-row">
            <div class="work-schedule__weekday" v-for="day in week" :key="day.date+'_'+day.day">
              <div class="work-schedule__day-of-week">
                <div class="work-schedule__date-wrapper">
                  <span class="work-schedule__date-wrapper-day">{{day.day}}</span>
                  <span class="work-schedule__date-wrapper-date">{{day.date}}</span>
                </div>
              </div>
              <div class="work-schedule__hours">
                <div class="work-schedule__cell" v-for="time in day.time.slice(0,-1)" :key="time" :style="cellWidth !== 10 ?{width:cellWidth+'px'} : {width:cellWidth+'px',fontSize:0.5+'rem'}">{{time}}</div>
              </div>  
            </div>
          </div>  

          <div class="work-schedule__schedule-rows" ref="workplace">     
            <template v-for="comPlace in comPlacesShows">
            <user-spec-plan-row   
                v-if="userSpecPlanRowShow(comPlace.id)"
                :key="comPlace.id"
                @mouseMove="mouseMove"
                :cellWidth="cellWidth"
                :sidebarCollapse="sidebarCollapse"
                :week="week"
                :xScroll="xScroll"
                :comPlace="comPlace"
                :zoom="zoom"
                :events="events"
                :eventStretching="eventStretching"
                :eventDrag="eventDrag"
                :eventCopy="eventCopy"
                :loadState="loadState"
                :userSpecPlanState="userSpecPlanState"
                :selectedPlace="selectedPlace"
                :keyPressed="keyPressed"
                :dayTimeValidation="dayTimeValidation"
                :selectedDate="selectedDate"
                :tooltipStyle="tooltipStyle"
                :selectedTime="selectedTime"
                :cursorStyle.sync="cursorStyle"
                @hideTooltip="hideTooltip"
                @startCopy="startCopy"
                @finishCopy="finishCopy"
                @startDrag="startDrag"
                @cancelStretching="cancelStretching"
                @startStretching="startStretching"
            />
          </template>
          </div>
      
        </div>

        <user-spec-plan
            :state="userSpecPlanState"
            :users="users"
            :comRooms="comRooms"
            :events="events"
            @cancel="userSpecPlanState.modalClose()"
            @createdUpdatedDeleted="userSpecPlanState.modalClose();load(true)"
        />

      </v-sheet>
      <v-progress-linear v-if="loadState.isLoading" indeterminate color="primary" style="z-index:10"/>
    </v-col>
  </v-row>
</template>


<script>
import moment from "moment";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
//import UserSpecPlanCalendarView from "@/views/UserSpec/UserSpecPlanCalendarView";
import UserSpecPlan from "@/views/UserSpec/UserSpecPlan";
import UserSpecPlanRow from "@/views/UserSpec/UserSpecPlanRow";
//import UserSpecPlanCard from "@/views/UserSpec/UserSpecPlanCard";

import DialogView from "@/components/DialogView";
import ProgressPage from "@/components/ProgressPage";
import SpecSelect from "@/views/Spec/components/SpecSelect";
import DatePicker from "@/components/DatePicker";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import Checkboxes from "@/componentsV2/base/Checkboxes.vue";
// import TooltipView from "@/components/TooltipView";

import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";

export default {
  //components: {UserSpecPlan, UserSpecPlanCalendarView},
  components: {
    ComBranchAutocompleteMultiple,
    ProgressPage, DialogView, UserSpecPlan, SpecSelect,UserSpecPlanRow, DatePicker,Checkboxes, UserAutocompleteMultiple},
  data: () => ({
    daySelected: moment(new Date()).format('YYYY-MM-DD'),
    zoomScale: {'k1':1,'k2':2,'k3':4,'k4':8},
    zoomKey:1,
    zoom:1,
    height: 600,
    width: 1000,

    loadState: new State(),

    comBranchs:[],
    comBranchsText:null,
    comBranchsSelected:[],

    specs:[],
    specsText:null,
    specsSelected:[],

    users:[],
    usersAllows:[],
    usersSelected:[],

    calendarReady:false,
    comRoomsInitial:[],
    comRooms:[],
    comPlaces:[],
    comPlacesShows:[],
    events:[],
    beginTime:'00:00',
    endTime:'00:00',
    intervalCountTime:0,
    eventDrag:null,

    //i
    week:[],
    step:30,
    cellWidth: 30,
    selectedTime: null,
    selectedDate: null,
    selectedPlace: null,
    selectedScheduleCell: {},
    eventStretching: null,
    stretchingDate: null,
    stretchingDayTimeEnd: null,
    cursorStyle: null,
    dayTimeValidation: true,
    tooltipStyle: null,
    mouseMoveEvent: false,
    sidebarCollapse: false,

    xScrollInitial: null,
    xScroll: null,

    userSpecPlanState: new State(),
    copyState: new State(),
    //copy by ctrl&click
    keyPressed: false,
    eventCopy:null,
    eventCopyCategory: null,
    eventCopyStart: null,
    eventCopyEnd: null,

    showDeleted: []

  }),
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
  },
  mounted(){
    this.$cookie.delete('userSpecPlans')
    this.$cookie.set('userSpecPlans', '/userSpecPlanMonth/', 30);
    // if(this.$store.state.comBranchId) {
    //   this.comBranchsSelected = this.$store.state.comBranchId;
    // } 
    this.load();
    this.resize();
    window.addEventListener('resize', ()=>{
      this.resize();
    }, true);
    this.calendarReady = true;

    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
  },
  computed:{
    isToday(){
      return this.daySelected===moment(new Date()).format('YYYY-MM-DD');
    },
    // cal(){
    //   return this.calendarReady ? this.$refs.calendar : null
    // },
    // nowY() {
    //   return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    // }
  },
  watch:{
    daySelected(){
      this.load();
      this.scrollToStart();
    },
    comBranchsSelected(){
      this.load();
    },
    specsSelected(){
      this.load();
    },
    usersSelected(){
      this.load();
    },
    showDeleted(){
      this.load();
    }
  },
  methods: {
    load(isFullUpdate=false){
      this.loadState.stateLoading();
      
      axios.all([
        this.comBranchs.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/comBranch/'),
        this.comRoomsInitial.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/comRoom/'),
        this.specs.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/spec/'),
        this.users.length>0 && !isFullUpdate ? null : axios.get(Api.host+'/user/?type[]=spec&type[]=admin&onPage=100000')
      ]).then(axios.spread((data1, data2, data3, data4) => {
        //comBranchs
        if(data1!==null) this.comBranchs = data1.data;

        let beginTime = moment('23:59', 'HH:mm');
        let endTime = moment('00:00', 'HH:mm');
        this.comBranchs.forEach((comBranch)=>{
          let index1 = parseInt(moment(this.daySelected, 'YYYY-MM-DD').format('e'));
          comBranch.workPlan.forEach(function(value, index){
            if (index===index1){
              let f = moment(value.begin, 'HH:mm');
              let e = moment(value.end, 'HH:mm');
              if(beginTime.unix()>f.unix()) beginTime = f;
              if(endTime.unix()<e.unix()) endTime = e;
            }
          });
        });
        this.beginTime = beginTime.format('HH:mm');
        this.endTime = endTime.format('HH:mm');
        this.intervalCountTime = moment.duration(moment(this.endTime, 'HH:mm').diff(moment(this.beginTime, 'HH:mm'))).asHours()*2;

        //comRooms
        if(data2!==null) {
          this.comRoomsInitial = data2.data;
        }
        this.comRooms = this.comRoomsInitial
        this.comPlacesShows = [];
        let newComRooms = []
        if(this.comBranchsSelected.length>0){
          
          this.comBranchsSelected.forEach((comBranchId)=>{           
            this.comRooms.forEach((comRoom)=>{
              if(comRoom.comFloor.comBuilding.comBranch.id==comBranchId.id){
                newComRooms.push(comRoom)
              }
            });
          });

          this.comRooms = newComRooms

          this.comRooms.forEach((comRoom)=>{
              comRoom.comPlaces.forEach((comPlace)=>{
                comPlace.comRoom = comRoom;
                this.comPlacesShows.push(comPlace);
              });            
          });
         
          // this.comBranchsSelected.forEach((comBranchId)=>{           
          //   this.comRooms.forEach((comRoom)=>{
          //     if(comRoom.comFloor.comBuilding.comBranch.id===comBranchId){
          //       comRoom.comPlaces.forEach((comPlace)=>{
          //         comPlace.comRoom = comRoom;
          //         this.comPlacesShows.push(comPlace);
          //       });
          //     }
          //   });
          // });
        } else {
          this.comRooms.forEach((comRoom)=>{
            comRoom.comPlaces.forEach((comPlace)=>{
              comPlace.comRoom = comRoom;
              this.comPlacesShows.push(comPlace);
            });
          });
        }
        this.resize();

        //specs
        if(data3!==null) this.specs = data3.data;

        //users
        if(data4!==null) this.users = data4.data.data;
        this.users.forEach(v=>{
          v.name = v.lastName+' '+v.firstName+' '+v.secondName;
        });
        if(this.specsSelected.length>0){
          this.usersAllows = [];
          this.specsSelected.forEach(specId=>{
            this.users.forEach(user=>{
              user.userSpecs.forEach((userSpec)=>{
                if(userSpec.spec.id===specId)
                  this.usersAllows.push(user);
              });
            });
          });
        } else {
          this.usersAllows = this.users;
        }
        

        //i
        this.week = [];
        for (var i = 0; i < 31; i++) {   
          let day = new Object();  
          let dayOfweek = moment(this.daySelected).add(i, 'd').format('ddd');
          let time = this.comBranchs[0].workPlan

          day.date = moment(this.daySelected).add(i, 'd').format('DD-MM-YYYY');
          day.dateYMD = moment(this.daySelected).add(i, 'd').format('YYYY-MM-DD');
          
          day.day = dayOfweek;   
          
          if (dayOfweek == 'пн') {
            day.time = this.weekTimeGrid(time,0);
          }
          if (dayOfweek == 'вт') {
            day.time = this.weekTimeGrid(time,1);
          }
          if (dayOfweek == 'ср') {
            day.time = this.weekTimeGrid(time,2);
          }
          if (dayOfweek == 'чт') {
            day.time = this.weekTimeGrid(time,3);
          }
          if (dayOfweek == 'пт') {
            day.time = this.weekTimeGrid(time,4);
          }
          if (dayOfweek == 'сб') {
            day.time = this.weekTimeGrid(time,5);
          }
          if (dayOfweek == 'вс') {
            day.time = this.weekTimeGrid(time,6);
          }

          
          this.week.push(day);
        }  

        this.dayTimeValidation = true
        this.mouseMoveEvent = false


        this.userSpecPlansLoad();

      }))
      .catch(e =>{
        Api.responseError(e)
        this.loadState.stateError();
      });
    },
    userSpecPlansLoad(){
      let status = this.showDeleted.length>0 ? 'deleted' : 'active';
      let url = Api.host+'/userSpecPlan/?status='+status;
      this.comBranchsSelected.forEach((v)=>{
        url +=(url.indexOf('?')===-1 ? '?' : '&')+'comBranch[]='+v.id;
      });
      url+=(url.indexOf('?')===-1 ? '?' : '&')+'begin='+this.daySelected;
      url+=(url.indexOf('?')===-1 ? '?' : '&')+'end='+moment(this.daySelected, 'YYYY-MM-DD').add(31, 'days').format('YYYY-MM-DD');
      axios.get(url)
          .then(r =>{
            this.events = [];
            r.data.forEach((userSpecPlan)=>{
              let show = false;
              if(this.specsSelected.length>0){
                this.specsSelected.forEach((specId)=>{
                  if(userSpecPlan.userSpec.spec.id===specId)
                    show = true;
                });
              } else{
                show = true;
              }

              let show1 = false;
              if(this.usersSelected.length>0){
                this.usersSelected.forEach((user)=>{
                  if(userSpecPlan.userSpec.user.id===user.id)
                    show1 = true;
                });
              } else{
                show1 = true;
              }

              if(show && show1)
                this.events.push({
                  userSpecPlan: userSpecPlan,
                  start: moment(userSpecPlan.begin, 'YYYY-MM-DD HH:mm:ss').toDate(),
                  end: moment(userSpecPlan.end, 'YYYY-MM-DD HH:mm:ss').toDate(),
                  color: 'rgb(245 245 245)',
                  timed: true,
                  category: userSpecPlan.comPlace.id
                });
            });
            this.loadState.stateSuccess();
          })
          .catch(e =>{
            Api.responseError(e)
            this.loadState.stateError();
          }); 
          
    },

    userSpecPlanUpdateTime(event){
      this.loadState.stateLoading();
      axios.put(Api.host+'/userSpecPlan/'+event.userSpecPlan.id+'/', {
        begin:moment(event.start).format('YYYY-MM-DD HH:mm:00'),
        end:moment(event.end).format('YYYY-MM-DD HH:mm:00'),
        comPlace: event.category
      })
      .then((r) =>{
        if(this.userSpecPlanState.isModalOpen)
          this.userSpecPlanState.modalOpen(r.data);
        this.load();
      })
      .catch(e =>{
        this.$store.commit('alertError', e.response.data.message);
        this.load();
      });
    },
    userSpecCopyEvent(event){
      this.loadState.stateLoading();

      axios.post(Api.host+'/userSpecPlan/', {
        comPlace: this.eventCopyCategory,
        userSpec:event.userSpecPlan.userSpec.id,
        begin:moment(this.eventCopyStart).format('YYYY-MM-DD HH:mm:00'),
        end:moment(this.eventCopyEnd).format('YYYY-MM-DD HH:mm:00'),
        type:event.userSpecPlan.type,
        gridMinutes:event.userSpecPlan.gridMinutes,
        userSpecCommentAdmin:event.userSpecPlan.userSpec.commentAdmin
      })
      .then(()=> {
        this.$store.commit('alertSuccess', 'План работы создан');
        this.load();
      })
      .catch((e)=> {
        this.$store.commit('alertError', e.response.data.message);
        this.load();
      });
    },

    //drag
    onKeyDown(event) {
      if (event.key === 'Control'||event.key === 'Alt') {
        this.keyPressed = event.key;
      }
    },
    onKeyUp(event) {
      if (event.key === 'Control'||event.key === 'Alt') {
        this.keyPressed = false;
        this.eventCopy = null;
      }
    },
    startDrag(event){
      this.eventDrag = event;
    },
    startCopy($event,event) {
      if(this.keyPressed){
        $event.preventDefault()
        $event.stopPropagation()
        
        this.eventCopy = event;

      }
    },
    finishCopy(event){
      this.validationError()
      if(this.dayTimeValidation) {
         this.userSpecCopyEvent(event); 
      }
    },
    mouseMove(event,workDayStart,workDayEnd,place,day) {
      let x = event.pageX
      let cardOffset = Math.ceil(x - event.target.getBoundingClientRect().left)
 
      this.selectedDate = day.dateYMD
      this.selectedPlace = place  
   
      this.tooltipStyle = {
            transform: 'translate3d('+cardOffset+'px, 0, 0)',
            opacity: 1
      }


      if (cardOffset % 5 === 0)  {
        this.selectedTime = moment(workDayStart, "HH:mm").add(cardOffset*this.zoom,'m').format("HH:mm");
        let time = this.selectedTime;
        let category = this.selectedPlace
        let date = this.selectedDate
        let dayTimeEnd = workDayEnd
 
        if(this.eventDrag!==null||this.eventCopy!==null){
          let interactEvent
          if (this.eventDrag) {
          interactEvent = this.eventDrag
          } else if (this.eventCopy) {
            interactEvent = this.eventCopy
          }
          
          if(typeof category!=='undefined'){
            let t = moment(date+' '+time, 'YYYY-MM-DD HH:mm');

            let diff = moment(interactEvent.end).diff(interactEvent.start, 'minutes');

            let beginTime = t.minute(t.minute() - (t.minute() % 5)).toDate();
            //let endTime = t.clone().add(diff, 'minutes').minute(t.minute() - (t.minute() % 5)).toDate();
            let endTime = moment(beginTime).add(diff, 'minutes').toDate();

            if (this.eventDrag) {
              this.eventDrag.category = category.id;
              this.eventDrag.start = beginTime;
              this.eventDrag.end = endTime;
            } else if (this.eventCopy&&this.keyPressed === 'Control') {
              this.eventCopyCategory = category.id;
              this.eventCopyStart = beginTime;
              this.eventCopyEnd = endTime;
            } else if (this.eventCopy&&this.keyPressed === 'Alt') { //same time
              
              let originalTimeStart = this.$tools.dateTimeFormat(this.eventCopy.start, 'HH:mm');
              let originalTimeFinish= this.$tools.dateTimeFormat(this.eventCopy.end, 'HH:mm');
              let newDate = this.$tools.dateTimeFormat(beginTime, 'YYYY-MM-DD');

              this.eventCopyCategory = category.id;
              this.eventCopyStart = moment(newDate+' '+originalTimeStart, 'YYYY-MM-DD HH:mm');
              this.eventCopyEnd = moment(newDate+' '+originalTimeFinish, 'YYYY-MM-DD HH:mm')
            }

            //validation 
            let interactEventStart = beginTime
            let interactEventEnd = endTime

            let validation = moment(dayTimeEnd[0], 'HH:mm').diff(moment(this.$tools.dateTimeFormat(interactEventEnd, 'HH:mm'), 'HH:mm'), 'minutes')
            let validation2 = this.$tools.dateTimeFormat(endTime, 'HH') - this.$tools.dateTimeFormat(beginTime, 'HH')

            //between day
            if (validation<0||validation2<0) {
              this.dayTimeValidation = false
            }
            else {
              this.dayTimeValidation = true
            }
            //between event
            let interactEventCategory = category.id
            let interactEventUser = interactEvent.userSpecPlan.userSpec.id

            this.events.filter(event => event.type != 'userSpecPlanNew').forEach(event =>{
              let category = event.category
              let dragCategory = interactEventCategory
              let start = moment(this.$tools.dateTimeFormat(event.start, 'YYYY-MM-DD HH:mm'))
              let end = moment(this.$tools.dateTimeFormat(event.end, 'YYYY-MM-DD HH:mm'))
              let dragStart = moment(this.$tools.dateTimeFormat(interactEventStart, 'YYYY-MM-DD HH:mm'))
              let dragEnd = moment(this.$tools.dateTimeFormat(interactEventEnd, 'YYYY-MM-DD HH:mm'))
              let user = event.userSpecPlan.userSpec.id
              let dragUser = interactEventUser
              if (
                category==dragCategory&&(
                dragStart.isBetween(start,end)||
                dragEnd.isBetween(start,end)||
                start.isBetween(dragStart,dragEnd)||
                end.isBetween(dragStart,dragEnd)||
                dragStart.isSame(start)&&dragEnd.isSame(end)&&user!=dragUser)
              ) {
                this.dayTimeValidation = false
              }
            
            })

            if(this.eventCopy===null){
              this.userSpecPlanNewRemove();
            }
          
          }
        } 
        else if (this.eventStretching!==null) {
            let endTime = moment(date+' '+time, 'YYYY-MM-DD HH:mm');

            if (this.eventStretching.end != endTime) {
              this.mouseMoveEvent = true
            }
            
            //date validation
            if (this.stretchingDate != date){
              this.eventStretching.end = moment(this.stretchingDate+' '+this.stretchingDayTimeEnd, 'YYYY-MM-DD HH:mm')
            }
            else {
              this.eventStretching.end = endTime;
            }
            
            //between event
            this.events.forEach(event =>{
              let category = event.category
              let dragCategory = this.eventStretching.category
              let start = moment(this.$tools.dateTimeFormat(event.start, 'YYYY-MM-DD HH:mm'))
              let end = moment(this.$tools.dateTimeFormat(event.end, 'YYYY-MM-DD HH:mm'))
              let stretchingStart = moment(this.$tools.dateTimeFormat(this.eventStretching.start, 'YYYY-MM-DD HH:mm'))
              let stretchingEnd = moment(this.$tools.dateTimeFormat(this.eventStretching.end, 'YYYY-MM-DD HH:mm'))
              if (
                  category==dragCategory&&(
                  stretchingEnd.isBetween(start,end)||
                  stretchingStart.isBefore(start)&&stretchingEnd.isAfter(end))
                ) {
                this.eventStretching.end = start;
              }
            })


          this.userSpecPlanNewRemove();
        }
      }
    },
    validationError() {
      if (this.dayTimeValidation == false) {
        this.load()
        this.$store.commit('alertError', 'Ошибка перемещения, время недоступно');
      }
    },
    cancelDrag(event){
      this.validationError()
      if(this.dayTimeValidation) {
         this.userSpecPlanUpdateTime(event); 
      }

      this.eventDrag = null;
    },
    //UserSpecPlan blank
    userSpecPlanNew({ category, date, time }){
      let t = moment(date+' '+time, 'YYYY-MM-DD HH:mm');
      let beginTime = t.clone().minute(t.minute() - (t.minute() % 5)).toDate();
      let endTime = t.clone().minute(t.minute() - (t.minute() % 5)).add(5, 'minutes').toDate();

      let valid = true;
      let startBlock = 0;
      let endBlock = 0;
      this.events.forEach((value)=>{
        if (value.type!=='userSpecPlanBlank'){
          startBlock = value.start.getTime();
          endBlock = value.end.getTime();
          if (value.category===category.id){
            if (beginTime<startBlock && endTime>startBlock)
              valid = false;
            if (beginTime>=startBlock && endTime<=endBlock)
              valid = false;
            if (beginTime<endBlock && endTime>endBlock)
              valid = false;
          }
        }
      });

      if(valid){
        this.userSpecPlanNewRemove();
        this.events.push({
          start: beginTime,
          end: endTime,
          color: 'primary',
          timed: true,
          category: category.id,
          comPlace: category,
          type:'userSpecPlanNew'
        });
      }
    },
    userSpecPlanNewRemove(){
      this.events = this.events.filter(v=>v.type!=='userSpecPlanNew');
    },

    copy(){
      this.copyState.stateLoading();

      let comBranchs = [];
      this.copyState.modalData3.forEach(branch=>{
        comBranchs.push(branch.id);
      })
      axios.post(Api.host+'/userSpecPlan/copy/', {
        fromBegin:this.copyState.modalData1[0],
        fromEnd:this.copyState.modalData1[this.copyState.modalData1.length-1],
        toBegin:this.copyState.modalData2,
        comBranchIds: comBranchs.join(', ')
      })
        .then((r) =>{
          this.$store.commit('alertSuccess', r.data.message);
          this.copyState.modalClose()
          this.copyState.stateSuccess();
          this.copyState.modalData2 = [];
          //this.daySelected = this.copyState.modalData2;
          this.load();
        })
        .catch(e =>{
          this.copyState.stateError(e);
        });
    },
    //calendar tools

    getEventColor(event) {
      return event.color
    },
    hm(interval){
      return interval.time;
    },

    //tools
    resize(){
      this.height = window.innerHeight-64;
      this.width = this.comPlacesShows.length*210;
      this.width += 36;
    },

    zoomChange(zoom){
      let newZoomKey = this.zoomKey+zoom
      if(newZoomKey>0&&newZoomKey<4) {
        this.zoomKey = newZoomKey
        this.zoom = this.zoomScale['k'+newZoomKey];
        this.step = 30 * this.zoomScale['k'+newZoomKey];
        this.load();
      }
    },

    setToday () {
      this.daySelected = moment(new Date()).format('YYYY-MM-DD');
      this.load();
    },



    //i
    weekTimeGrid(time,index) {
      let WorkingHours=[];
      WorkingHours.push(moment(time[index].begin, "HH:mm").format("HH:mm"));
      let i = time[index].begin;  
      while (moment(i, 'HH:mm').isBetween(moment(time[index].begin, 'HH:mm').subtract(1, 'minutes'),moment(time[index].end, 'HH:mm'))) { 
        i = moment(i, "HH:mm").add(this.step,'m').format("HH:mm");
        
        if (i === '00:00') {
          i = '23:59'
        }
        WorkingHours.push(i);
      }
     
      return WorkingHours;
    },
    scheduleCardOffset(dayStart,userDayStart) {
      let dStart = moment(dayStart, "HH:mm")
      let uDStart = moment(userDayStart, "HH:mm")
      let duration = uDStart.diff(dStart , 'minutes')/this.zoom
      return duration 
    },
    hideTooltip() {
        this.tooltipStyle = {
          opacity: 0
        }
    },
    //stretchiing
    startStretching(event,date,dayTimeEnd){
      console.log(event)
      console.log(date)
      console.log(dayTimeEnd)
      if (!this.keyPressed) {
        this.stretchingDate = date;
        this.stretchingDayTimeEnd = dayTimeEnd
        this.eventStretching = event;
      }
    },
    cancelStretching(event){
      this.validationError()
      if(this.dayTimeValidation) {
        this.userSpecPlanUpdateTime(event);
      }

      
      this.stretchingDate = null;
      this.stretchingDayTimeEnd = null

      if (this.mouseMoveEvent == true){
        setTimeout(() =>  {
          this.eventStretching = null
        }, 100);
      }
      else {
        this.eventStretching = null
      }


      this.mouseMoveEvent = false
      
    },
    calendarScroll(event) {
      const scrollX = event.target.scrollLeft;
      this.xScroll = scrollX;
    },
    scrollToStart() {
      if(this.$refs.scrollContainer) {
        this.$refs.scrollContainer.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      }
    },
    userSpecPlanRowShow(comPlaceId) {
      if (
      (this.usersSelected.length>0&&this.events.filter(e=>e.userSpecPlan.comPlace.id===comPlaceId).length>0)
      ||this.specsSelected.length>0&&this.events.filter(e=>e.userSpecPlan.comPlace.id===comPlaceId).length>0
      ||this.usersSelected.length===0&&this.specsSelected.length===0
      )
        return true
      else
        return false
    }
  }
}
</script>

<style lang="scss" scoped>
  $indent: 200px;
  $grey:#e0e0e0;
  $border: #e0e0e0 1px solid;
  $cell: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABGCAYAAADb7SQ4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LmRhYmFjYmIsIDIwMjEvMDQvMTQtMDA6Mzk6NDQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMi40IChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0MUI2QTBBQzBDRjcxMUVEOUE5NEJDNENFM0IxOUU2OCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0MUI2QTBBRDBDRjcxMUVEOUE5NEJDNENFM0IxOUU2OCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQxQjZBMEFBMENGNzExRUQ5QTk0QkM0Q0UzQjE5RTY4IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQxQjZBMEFCMENGNzExRUQ5QTk0QkM0Q0UzQjE5RTY4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+5I7JhQAAAEVJREFUeNrszUEBACAMBKCbRda/1ZpoC30IBaiZ2Umqu3PTyiNisVgsFovFYrFYLBaLxWKxWCwWi8VisVgsFov/iI8AAwAdNQSJa6ulbwAAAABJRU5ErkJggg==') top left/ 30px 100% repeat-x;
  %separator {
        content: "";
        position: absolute;      
        transform: translate3d(-1px, 0, 0);
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: 3px 0px 0px 0px rgba(66, 142, 218, 0.3);
        z-index: 9;
  }
  %cell-size {
    width: 30px;
  }
  .work-schedule {
    background: #ffffff;
    padding: 1rem;
    overflow-x: auto;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    &__dates-row {
      margin-left: $indent;
      width: 100%;
      display: flex;
    }
    &__weekday {
      // border: $border;
      position: relative;
      border-bottom: none;
      display: inline-flex;
      flex-direction: column;
      text-align: center;
      &::after {
        @extend %separator;
      }
    }  
    &__day-of-week {
        border-top: $border;
        border-left: $border;
        position: relative;
        height: 25px;
    }
    &__date-wrapper {
      position: absolute;
      inset: 0;
      z-index: 999;

      &-day {
        font-weight: bold;
        margin-right: .5rem;
        display: inline-block;
        background: #ffffff;
      }
      &-date { 
        white-space: nowrap;
        display: inline-block;
        background: #ffffff;
      }
    }
    &__hours {
      display: flex;
      background: $cell;     
      border-top: 1px solid #e0e0e0;  
    }
    &__cell {
      height: 70px;
      writing-mode: vertical-rl;
      font-size: .825rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        border-right: none;
      }
    }
    &__schedule-rows {
      display: inline-flex;
      flex-direction: column;
    }
  }
  .schedule-row:last-child {
    border-bottom:1px solid $grey
  }
  .sidebar-collapse-btn {
     position: absolute;
     z-index: 9;
     left: 90px;
     top: 30px;
  }
  
</style>